import { useEffect, useState } from "react";
import { IoLanguage } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";

const languages = [
  { name: "English", key: "en" },
  { name: "Farsi", key: "fa" },
  { name: "Finnish", key: "fi" },
];

const LanguageChange = ({ handleLanguage }) => {
  const [IsShowLanguage, setIsShowLanguage] = useState(false);
  const [language, setLanguage] = useState("");

  const handleShow = () => {
    setIsShowLanguage((prev) => !prev);
  };

  const handleChange = (lang) => {
    localStorage.setItem("lang", lang);
    if (lang === "fa") localStorage.setItem("rtl", true);
    else localStorage.setItem("rtl", false);
    handleLanguage(lang);
    setIsShowLanguage(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".language-dropdown")) {
        setIsShowLanguage(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentLang = localStorage.getItem("lang");
    setLanguage(currentLang);
  }, []);

  return (
    <div className="relative flex justify-center language-dropdown">
      <button onClick={handleShow}>
        <IoLanguage size={24} />
      </button>
      <div
        className={`bg-white flex flex-col divide-y justify-center absolute z-50 rounded-md shadow-md overflow-hidden duration-200 ${
          IsShowLanguage ? "top-7 opacity-100" : "top-24 opacity-0 pointer-events-none"
        }`}
      >
        {languages.map((item) => (
          <div
            className="px-5 flex justify-center items-center gap-1 py-2 text-sm text-gray-700 w-full cursor-pointer duration-200 hover:bg-gray-100"
            onClick={() => handleChange(item.key)}
            key={item.key}
          >
            {item.name} {language === item.key && <FaCheck size={10} className="text-blue-600" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageChange;

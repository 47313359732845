import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FaCarSide } from "react-icons/fa";
import StrinLocalized from "../../Language/language.lang";
import "leaflet-geosearch/dist/geosearch.css";
import { Avatar, CircularProgress } from "@mui/material";
import { FaLocationDot, FaMapPin } from "react-icons/fa6";
import carIconIMG from "../../assets/carIcon.png"

const SharedTripMap = ({ data }) => {
  const mapRef = useRef();

  const carIcon = L.divIcon({
    html: renderToStaticMarkup(
      // <FaCarSide color="#df1d74" style={{ width: "30px", height: "30px" }} />
      <img src={carIconIMG} className="w-16 h-16" />
    ),
  });

  const customIcon = (iconColor) =>
    L.divIcon({
      html: renderToStaticMarkup(
        <FaLocationDot color={iconColor || "#ff0000"} className="size-10" />
      ),
      className: "",
    });

  const { drop_of_point, destination, pickup_point } = data.passenger;
  const center = [(pickup_point?.lat + drop_of_point.lat + data.driver.lat) / 3, (pickup_point.lng + drop_of_point.lng + data.driver.lng) / 3];

  if (!data.driver.lat && !data.driver.lng) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ position: "relative", height: "100vh" }} ref={mapRef}>
      {/* INFO */}
      <div className="absolute z-[1000] bottom-0 p-3 md:p-6 md:left-0 w-full md:w-auto">
        <div className="bg-white rounded-md shadow-lg h-full w-full px-5 uppercase">
          <h4 className="border-b border-b-gray-300 text-green-400 text- pb-3 pt-4">
            <span className="text-gray-900">{data.passenger.name || "-"}</span>{" "}
            is en route
          </h4>

          <div className="flex flex-row gap-3 justify-start items-center py-4">
            <Avatar
              sx={{ bgcolor: "#c8e200", height: "64px", width: "64px" }}
              alt={data.driver.name}
              src={data.driver.img}
            />
            <div>
              <h2>{data.driver.name || "-"}</h2>
              <h3 className="text-gray-400 text-base">
                {data.driver.car || "-"}
              </h3>
              <h3 className="text-gray-400 text-base">
                {data.driver.plaque || "-"}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <MapContainer
        center={center || [60.166098, 24.9421019]}
        zoom={10}
        style={{
          height: "100%",
          width: "100%",
        }}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        <ZoomControl position="topright" />

        <Marker icon={carIcon} position={[data.driver.lat, data.driver.lng]} />

        <Marker
          position={[pickup_point.lat, pickup_point.lng]}
          icon={L.divIcon({
            html: renderToStaticMarkup(
              <FaMapPin color="#00a8ff" className="size-10" />
            ),
          })}
        >
          <Popup>{StrinLocalized.PickupAddress}: {pickup_point.address}</Popup>
        </Marker>

        {destination.map((marker, index) =>
          marker.lat && marker.lng ? (
            <Marker
              key={marker._id || index}
              position={[marker.lat, marker.lng]}
              icon={customIcon(markerColor[index])}
            >
              <Popup>{marker.address}</Popup>
            </Marker>
          ) : null
        )}

        <Marker
          position={[drop_of_point.lat, drop_of_point.lng]}
          icon={customIcon("#c23616")}
        >
          <Popup>{StrinLocalized.DropAddress}: {drop_of_point.address}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default SharedTripMap;

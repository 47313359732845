import React, { useState, useRef } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import TextBox from "../../Inputs/TextBox/textBox.component";
import { CalenderWrapper } from "./datepickerStyles/datepicker.style";
import { showCal } from "./datepickerUtils/datepicker.utils";
import CalenderIcon from "../../../Icons/inputIcons/Calender/calender.icon";
import PropTypes from "prop-types";
import jalaali from 'jalaali-js';

const Date = ({ placeHolder, width, height, onCustomSelect, value }) => {
  const [showCalender, setShowCalender] = useState(false);
  const claRef = useRef();
  const lang = localStorage.getItem("lang") || "en";

  function convertToGregorian(jalaliDate) {
    if (!jalaliDate) return null;
    const { day, month, year } = jalaliDate;
    const gregorianDate = jalaali.toGregorian(year, month, day);
    return {
      day: gregorianDate.gd,
      month: gregorianDate.gm,
      year: gregorianDate.gy,
    };
  }

  function convertToJalali(gregorianDate) {
    if (!gregorianDate) return null;
    const { day, month, year } = gregorianDate;
    const jalaliDate = jalaali.toJalaali(year, month, day);
    return {
      day: jalaliDate.jd,
      month: jalaliDate.jm,
      year: jalaliDate.jy,
    };
  }

  const getFormattedValue = () => {
    if (!value || value.length === 0) return null;
    return lang === "fa" ? convertToJalali(value[0]) : value[0];
  };

  return (
    <div ref={claRef} style={{ position: "relative" }}>
      <TextBox
        width={width}
        height={height}
        placeHolder={placeHolder}
        onClick={() => setShowCalender(true)}
        icon={<CalenderIcon />}
        value={
          value?.length > 0
            ? lang === "fa"
              ? `${convertToJalali(value[0]).year}/${convertToJalali(value[0]).month}/${convertToJalali(value[0]).day}`
              : `${value[0].year}/${value[0].month}/${value[0].day}`
            : ""
        }
        autoComplete="off"
      />
      {showCalender && (
        <CalenderWrapper offset={claRef.current.getBoundingClientRect().left}>
          <Calendar
            value={getFormattedValue()}
            onChange={(values) => {
              if (values) {
                const date = lang === "fa" ? [convertToGregorian(values)] : [values];
                onCustomSelect(date);
              }
              setShowCalender(false);
            }}
            shouldHighlightWeekends
            locale={lang === "fa" ? "fa" : "en"}
          />
        </CalenderWrapper>
      )}
    </div>
  );
};


Date.propTypes = {
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  bottom: PropTypes.number,
  onCustomSelect: PropTypes.func,
};

export default Date;

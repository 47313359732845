import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getList } from "../../Utils/getList";
import axiosInstance from "../../HelperAxios/axios";
import { toast } from "react-toastify";
import StrinLocalized from "../../Language/language.lang";
import SharedTripMap from "../../components/OpenStreetMap/SharedTripMap";

const ShareScreenPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [newData, setNewData] = useState({
    driver: {
      name: "",
      car: "",
      plaque: "",
      lat: 0,
      lng: 0,
      img: "",
    },
    passenger: {
      name: "",
      lat: 0,
      lng: 0,
    },
    status: false,
  });

  const requestToGetData = () => {
    setData([]);
    setIsLoading(true);
    const url = `/shared-trip/get/${id}`;
    getList(
      (res) => {
        const tempData = res.length > 1 ? res[0] : res;
        setData(tempData);
        setIsLoading(false);
      },
      () => {},
      url
    );

    // axiosInstance
    // .get(url)
    // .then((res) => {
    //   setData2(res);
    //   setIsLoading(false);
    // })
    // .catch((err) => {
    //   console.log(err);
    //   toast.error(err.response?.data.message);
    // });
  };

  useEffect(() => {
    requestToGetData();
  }, []);

  useEffect(() => {
    if (data) {
      const firstData = data[0]
      setNewData({
        driver: {
          name: `${firstData?.driverFirstName || "-"} ${firstData?.driverLastName || "-"}`,
          car: `${firstData?.carBrand || "-"} ${firstData?.carModel || "-"}`,
          plaque: firstData?.carPlate || "-",
          lat: firstData?.driverLocation?.coordinates[1],
          lng: firstData?.driverLocation?.coordinates[0],
          img: "-",
        },
        passenger: {
          name: `${firstData?.passengerFirstName || "-"} ${firstData?.passengerLastName || "-"}`,
          pickup_point: {
            lat: firstData?.pickup_point?.lat,
            lng: firstData?.pickup_point?.lng,
            address: firstData?.pickup_point?.address,
          },
          drop_of_point: {
            lat: firstData?.drop_of_point?.lat,
            lng: firstData?.drop_of_point?.lng,
            address: firstData?.drop_of_point?.address,
          },
          destination: firstData?.destination,
        },
        status: true,
      })
    }
  }, [data])

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading && !newData.status ? (
        <div className="w-screen h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <SharedTripMap data={newData} />
      )}
    </Box>
  );
};

export default ShareScreenPage;
